export const idlFactory = ({ IDL }) => {
  const Branch = IDL.Rec();
  const Branch_2 = IDL.Rec();
  const List = IDL.Rec();
  const List_1 = IDL.Rec();
  const List_2 = IDL.Rec();
  const List_3 = IDL.Rec();
  const Trie_1 = IDL.Rec();
  const Trie_3 = IDL.Rec();
  const ENV = IDL.Record({
    'wallet_canister_id' : IDL.Principal,
    'vesting_canister_id' : IDL.Principal,
    'old_modclub_canister_id' : IDL.Principal,
    'modclub_canister_id' : IDL.Principal,
    'rs_canister_id' : IDL.Principal,
    'auth_canister_id' : IDL.Principal,
    'modclub_assets_canister_id' : IDL.Principal,
  });
  const PohVerificationStatus = IDL.Variant({
    'notSubmitted' : IDL.Null,
    'verified' : IDL.Null,
    'expired' : IDL.Null,
    'pending' : IDL.Null,
    'startPoh' : IDL.Null,
    'rejectedDuplicate' : IDL.Null,
    'rejected' : IDL.Null,
    'processing' : IDL.Null,
  });
  const PohChallengeStatus = IDL.Variant({
    'notSubmitted' : IDL.Null,
    'verified' : IDL.Null,
    'expired' : IDL.Null,
    'pending' : IDL.Null,
    'rejectedDuplicate' : IDL.Null,
    'rejected' : IDL.Null,
    'processing' : IDL.Null,
  });
  const ChallengeResponse = IDL.Record({
    'status' : PohChallengeStatus,
    'completedAt' : IDL.Opt(IDL.Int),
    'submittedAt' : IDL.Opt(IDL.Int),
    'challengeId' : IDL.Text,
    'requestedAt' : IDL.Opt(IDL.Int),
  });
  const PohVerificationResponsePlus = IDL.Record({
    'status' : PohVerificationStatus,
    'completedAt' : IDL.Opt(IDL.Int),
    'token' : IDL.Opt(IDL.Text),
    'rejectionReasons' : IDL.Vec(IDL.Text),
    'submittedAt' : IDL.Opt(IDL.Int),
    'isFirstAssociation' : IDL.Bool,
    'providerId' : IDL.Principal,
    'challenges' : IDL.Vec(ChallengeResponse),
    'requestedAt' : IDL.Opt(IDL.Int),
    'providerUserId' : IDL.Text,
  });
  const ProviderError = IDL.Variant({
    'ProviderAdminIsAlreadyRegistered' : IDL.Null,
    'InvalidContentType' : IDL.Null,
    'NotFound' : IDL.Null,
    'Unauthorized' : IDL.Null,
    'RequiresWhitelisting' : IDL.Null,
    'InvalidContentStatus' : IDL.Null,
    'InvalidProvider' : IDL.Null,
    'ProviderIsRegistered' : IDL.Null,
  });
  const ProviderResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : ProviderError,
  });
  const UserId = IDL.Principal;
  const Timestamp = IDL.Int;
  const Role = IDL.Variant({
    'admin' : IDL.Null,
    'moderator' : IDL.Null,
    'owner' : IDL.Null,
  });
  const ProfileStable = IDL.Record({
    'id' : UserId,
    'userName' : IDL.Text,
    'subaccounts' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Nat8))),
    'createdAt' : Timestamp,
    'role' : Role,
    'email' : IDL.Text,
    'updatedAt' : Timestamp,
  });
  const Result_2 = IDL.Variant({ 'ok' : IDL.Bool, 'err' : IDL.Text });
  const Subaccount = IDL.Vec(IDL.Nat8);
  const Tokens = IDL.Nat;
  const CanClaimLockedResponse = IDL.Record({
    'claimPrice' : Tokens,
    'claimAmount' : Tokens,
    'canClaim' : IDL.Bool,
  });
  const Result_14 = IDL.Variant({
    'ok' : CanClaimLockedResponse,
    'err' : IDL.Text,
  });
  const Result_6 = IDL.Variant({ 'ok' : IDL.Nat, 'err' : IDL.Text });
  const ReservedId = IDL.Text;
  const Reserved = IDL.Record({
    'id' : ReservedId,
    'createdAt' : Timestamp,
    'profileId' : IDL.Text,
    'reservedExpiryTime' : Timestamp,
    'updatedAt' : Timestamp,
  });
  const POHVoteError = IDL.Variant({
    'reservationExpire' : IDL.Null,
    'userNotPermitted' : IDL.Null,
    'contentAlreadyReviewed' : IDL.Null,
    'pohNotConfiguredForProvider' : IDL.Null,
    'voteAlreadyFinalized' : IDL.Null,
    'invalidRules' : IDL.Null,
    'mustMakeReservation' : IDL.Null,
    'userAlreadyReserved' : IDL.Null,
    'notCompletedUser' : IDL.Null,
    'userAlreadyVoted' : IDL.Null,
  });
  const Result_13 = IDL.Variant({ 'ok' : Reserved, 'err' : POHVoteError });
  const ContentStatus = IDL.Variant({
    'new' : IDL.Null,
    'approved' : IDL.Null,
    'rejected' : IDL.Null,
  });
  const ContentType = IDL.Variant({
    'media' : IDL.Null,
    'imageBlob' : IDL.Null,
    'text' : IDL.Null,
    'htmlContent' : IDL.Null,
    'imageUrl' : IDL.Null,
    'multiText' : IDL.Null,
  });
  const VoteId = IDL.Text;
  const Decision = IDL.Variant({
    'approved' : IDL.Null,
    'rejected' : IDL.Null,
  });
  const UserLevel__2 = IDL.Variant({
    'junior' : IDL.Null,
    'novice' : IDL.Null,
    'senior1' : IDL.Null,
    'senior2' : IDL.Null,
    'senior3' : IDL.Null,
  });
  const RuleId = IDL.Text;
  const VoteV2 = IDL.Record({
    'id' : VoteId,
    'contentId' : IDL.Text,
    'decision' : Decision,
    'rsReceived' : IDL.Opt(IDL.Int),
    'lockedReward' : IDL.Opt(IDL.Float64),
    'userId' : UserId,
    'createdAt' : Timestamp,
    'rsBeforeVoting' : IDL.Int,
    'level' : UserLevel__2,
    'totalReward' : IDL.Opt(IDL.Float64),
    'violatedRules' : IDL.Opt(IDL.Vec(RuleId)),
  });
  const Decision__1 = IDL.Variant({
    'approved' : IDL.Null,
    'rejected' : IDL.Null,
  });
  const UserLevel__1 = IDL.Variant({
    'junior' : IDL.Null,
    'novice' : IDL.Null,
    'senior1' : IDL.Null,
    'senior2' : IDL.Null,
    'senior3' : IDL.Null,
  });
  const PohRulesViolated__1 = IDL.Record({
    'ruleId' : IDL.Text,
    'challengeId' : IDL.Text,
  });
  const PohVote = IDL.Record({
    'id' : IDL.Text,
    'contentId' : IDL.Text,
    'decision' : Decision__1,
    'rsReceived' : IDL.Opt(IDL.Float64),
    'lockedReward' : IDL.Opt(IDL.Float64),
    'userId' : IDL.Principal,
    'createdAt' : IDL.Int,
    'rsBeforeVoting' : IDL.Float64,
    'level' : UserLevel__1,
    'totalReward' : IDL.Opt(IDL.Float64),
    'violatedRules' : IDL.Vec(PohRulesViolated__1),
  });
  const ProviderId = IDL.Principal;
  const Activity = IDL.Record({
    'status' : ContentStatus,
    'reward' : IDL.Float64,
    'title' : IDL.Opt(IDL.Text),
    'voteCount' : IDL.Nat,
    'contentType' : ContentType,
    'rewardRelease' : Timestamp,
    'createdAt' : Timestamp,
    'vote' : IDL.Opt(VoteV2),
    'minStake' : IDL.Nat,
    'updatedAt' : IDL.Opt(Timestamp),
    'pohVote' : IDL.Opt(PohVote),
    'providerName' : IDL.Text,
    'providerId' : ProviderId,
    'requiredVotes' : IDL.Nat,
  });
  const ContentId = IDL.Text;
  const ReceiptId = IDL.Text;
  const Receipt = IDL.Record({
    'id' : ReceiptId,
    'cost' : IDL.Int,
    'createdAt' : Timestamp,
  });
  const CategoryId = IDL.Text;
  const Image = IDL.Record({
    'imageType' : IDL.Text,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const VoteParamsId = IDL.Text;
  const Level = IDL.Variant({
    'normal' : IDL.Null,
    'hard' : IDL.Null,
    'xhard' : IDL.Null,
    'simple' : IDL.Null,
  });
  const Complexity = IDL.Record({ 'expiryTime' : Timestamp, 'level' : Level });
  const VoteParameters = IDL.Record({
    'id' : VoteParamsId,
    'complexity' : Complexity,
    'createdAt' : Timestamp,
    'updatedAt' : Timestamp,
    'requiredVotes' : IDL.Int,
  });
  const ContentPlus = IDL.Record({
    'id' : ContentId,
    'status' : ContentStatus,
    'title' : IDL.Opt(IDL.Text),
    'voteCount' : IDL.Nat,
    'receipt' : Receipt,
    'contentType' : ContentType,
    'createdAt' : Timestamp,
    'text' : IDL.Opt(IDL.Text),
    'contentCanisterId' : IDL.Opt(IDL.Principal),
    'contentCategory' : CategoryId,
    'sourceId' : IDL.Text,
    'minStake' : IDL.Nat,
    'updatedAt' : Timestamp,
    'reservedList' : IDL.Vec(Reserved),
    'providerName' : IDL.Text,
    'image' : IDL.Opt(Image),
    'hasVoted' : IDL.Opt(IDL.Bool),
    'providerId' : IDL.Principal,
    'voteParameters' : VoteParameters,
    'requiredVotes' : IDL.Nat,
  });
  const ViolatedRules__1 = IDL.Record({
    'ruleId' : IDL.Text,
    'ruleDesc' : IDL.Text,
  });
  const PohChallengeType = IDL.Variant({
    'dl' : IDL.Null,
    'ssn' : IDL.Null,
    'userName' : IDL.Null,
    'uniquePohVideo' : IDL.Null,
    'fullName' : IDL.Null,
    'email' : IDL.Null,
    'selfVideo' : IDL.Null,
    'selfPic' : IDL.Null,
  });
  const PohTaskData = IDL.Record({
    'dataCanisterId' : IDL.Opt(IDL.Principal),
    'status' : PohChallengeStatus,
    'completedOn' : IDL.Int,
    'contentId' : IDL.Opt(IDL.Text),
    'allowedViolationRules' : IDL.Vec(ViolatedRules__1),
    'userId' : IDL.Principal,
    'createdAt' : IDL.Int,
    'submittedAt' : IDL.Int,
    'updatedAt' : IDL.Int,
    'challengeId' : IDL.Text,
    'challengeType' : PohChallengeType,
    'wordList' : IDL.Opt(IDL.Vec(IDL.Text)),
  });
  const PohTaskPlusForAdmin = IDL.Record({
    'status' : ContentStatus,
    'completedOn' : IDL.Int,
    'profileImageUrlSuffix' : IDL.Opt(IDL.Text),
    'voteCount' : IDL.Nat,
    'submittedAt' : IDL.Int,
    'userUserName' : IDL.Text,
    'userModClubId' : IDL.Text,
    'pohTaskData' : IDL.Vec(PohTaskData),
    'userEmailId' : IDL.Text,
    'packageId' : IDL.Text,
  });
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const ContentCategory = IDL.Record({
    'id' : CategoryId,
    'pid' : IDL.Opt(CategoryId),
    'title' : IDL.Text,
    'providerId' : IDL.Principal,
  });
  const ProviderSettings = IDL.Record({
    'minStaked' : IDL.Nat,
    'requiredVotes' : IDL.Nat,
  });
  const ProviderStable = IDL.Record({
    'id' : IDL.Principal,
    'subaccounts' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Nat8))),
    'name' : IDL.Text,
    'createdAt' : Timestamp,
    'description' : IDL.Text,
    'updatedAt' : Timestamp,
    'settings' : ProviderSettings,
    'image' : IDL.Opt(Image),
  });
  const ViolatedRules = IDL.Record({
    'id' : RuleId,
    'rejectionCount' : IDL.Nat,
  });
  const ContentResult = IDL.Record({
    'status' : ContentStatus,
    'approvedCount' : IDL.Nat,
    'sourceId' : IDL.Text,
    'violatedRules' : IDL.Vec(ViolatedRules),
    'rejectedCount' : IDL.Nat,
  });
  const Result_12 = IDL.Variant({
    'ok' : IDL.Record({
      'topSeniors' : IDL.Nat,
      'novice' : IDL.Nat,
      'juniors' : IDL.Nat,
      'seniors' : IDL.Nat,
    }),
    'err' : IDL.Text,
  });
  const UserLevel = IDL.Variant({
    'junior' : IDL.Null,
    'novice' : IDL.Null,
    'senior1' : IDL.Null,
    'senior2' : IDL.Null,
    'senior3' : IDL.Null,
  });
  const Result_11 = IDL.Variant({
    'ok' : IDL.Vec(
      IDL.Tuple(IDL.Principal, IDL.Bool, IDL.Nat, IDL.Nat, IDL.Text)
    ),
    'err' : IDL.Text,
  });
  const ModeratorLeaderboard = IDL.Record({
    'id' : UserId,
    'rs' : IDL.Float64,
    'completedVoteCount' : IDL.Int,
    'userName' : IDL.Text,
    'rewardsEarned' : IDL.Int,
    'lastVoted' : IDL.Opt(Timestamp),
    'performance' : IDL.Float64,
  });
  const Hash = IDL.Nat32;
  const Key_1 = IDL.Record({ 'key' : IDL.Principal, 'hash' : Hash });
  const Branch_3 = IDL.Record({
    'left' : Trie_3,
    'size' : IDL.Nat,
    'right' : Trie_3,
  });
  const Key = IDL.Record({ 'key' : IDL.Text, 'hash' : Hash });
  List_2.fill(IDL.Opt(IDL.Tuple(IDL.Tuple(Key, IDL.Null), List_2)));
  const AssocList_3 = IDL.Opt(IDL.Tuple(IDL.Tuple(Key, IDL.Null), List_2));
  const Leaf_3 = IDL.Record({ 'size' : IDL.Nat, 'keyvals' : AssocList_3 });
  Trie_3.fill(
    IDL.Variant({ 'branch' : Branch_3, 'leaf' : Leaf_3, 'empty' : IDL.Null })
  );
  List_3.fill(IDL.Opt(IDL.Tuple(IDL.Tuple(Key_1, Trie_3), List_3)));
  const AssocList_2 = IDL.Opt(IDL.Tuple(IDL.Tuple(Key_1, Trie_3), List_3));
  const Leaf_2 = IDL.Record({ 'size' : IDL.Nat, 'keyvals' : AssocList_2 });
  const Trie_2 = IDL.Variant({
    'branch' : Branch_2,
    'leaf' : Leaf_2,
    'empty' : IDL.Null,
  });
  Branch_2.fill(
    IDL.Record({ 'left' : Trie_2, 'size' : IDL.Nat, 'right' : Trie_2 })
  );
  const Trie2D_1 = IDL.Variant({
    'branch' : Branch_2,
    'leaf' : Leaf_2,
    'empty' : IDL.Null,
  });
  const RelShared_1 = IDL.Record({ 'forw' : Trie2D_1 });
  const SubscribePohMessage = IDL.Record({
    'callback' : IDL.Func([PohVerificationResponsePlus], [], ['oneway']),
  });
  const PohChallengeRequiredField = IDL.Variant({
    'imageBlob' : IDL.Null,
    'textBlob' : IDL.Null,
    'videoBlob' : IDL.Null,
    'profileFieldBlobs' : IDL.Null,
  });
  const PohChallenges = IDL.Record({
    'allowedViolationRules' : IDL.Vec(ViolatedRules__1),
    'createdAt' : IDL.Int,
    'dependentChallengeId' : IDL.Opt(IDL.Vec(IDL.Text)),
    'updatedAt' : IDL.Int,
    'challengeId' : IDL.Text,
    'challengeDescription' : IDL.Text,
    'challengeName' : IDL.Text,
    'challengeType' : PohChallengeType,
    'requiredField' : PohChallengeRequiredField,
  });
  const PohProviderAndUserData = IDL.Record({
    'token' : IDL.Text,
    'generatedAt' : IDL.Int,
    'providerId' : IDL.Principal,
    'providerUserId' : IDL.Text,
  });
  const PohChallengePackage = IDL.Record({
    'id' : IDL.Text,
    'title' : IDL.Opt(IDL.Text),
    'userId' : IDL.Principal,
    'createdAt' : IDL.Int,
    'updatedAt' : IDL.Int,
    'challengeIds' : IDL.Vec(IDL.Text),
  });
  const PohChallengesAttemptV1 = IDL.Record({
    'dataCanisterId' : IDL.Opt(IDL.Principal),
    'status' : PohChallengeStatus,
    'completedOn' : IDL.Int,
    'attemptId' : IDL.Opt(IDL.Text),
    'userId' : IDL.Principal,
    'createdAt' : IDL.Int,
    'submittedAt' : IDL.Int,
    'updatedAt' : IDL.Int,
    'challengeId' : IDL.Text,
    'challengeDescription' : IDL.Text,
    'challengeName' : IDL.Text,
    'challengeType' : PohChallengeType,
    'wordList' : IDL.Opt(IDL.Vec(IDL.Text)),
  });
  const Branch_1 = IDL.Record({
    'left' : Trie_1,
    'size' : IDL.Nat,
    'right' : Trie_1,
  });
  List.fill(IDL.Opt(IDL.Tuple(IDL.Tuple(Key_1, IDL.Null), List)));
  const AssocList_1 = IDL.Opt(IDL.Tuple(IDL.Tuple(Key_1, IDL.Null), List));
  const Leaf_1 = IDL.Record({ 'size' : IDL.Nat, 'keyvals' : AssocList_1 });
  Trie_1.fill(
    IDL.Variant({ 'branch' : Branch_1, 'leaf' : Leaf_1, 'empty' : IDL.Null })
  );
  List_1.fill(IDL.Opt(IDL.Tuple(IDL.Tuple(Key, Trie_1), List_1)));
  const AssocList = IDL.Opt(IDL.Tuple(IDL.Tuple(Key, Trie_1), List_1));
  const Leaf = IDL.Record({ 'size' : IDL.Nat, 'keyvals' : AssocList });
  const Trie = IDL.Variant({
    'branch' : Branch,
    'leaf' : Leaf,
    'empty' : IDL.Null,
  });
  Branch.fill(IDL.Record({ 'left' : Trie, 'size' : IDL.Nat, 'right' : Trie }));
  const Trie2D = IDL.Variant({
    'branch' : Branch,
    'leaf' : Leaf,
    'empty' : IDL.Null,
  });
  const RelShared = IDL.Record({ 'forw' : Trie2D });
  const PohStableState = IDL.Record({
    'userToPohChallengePackageId' : RelShared_1,
    'providersCallback' : IDL.Vec(
      IDL.Tuple(IDL.Principal, SubscribePohMessage)
    ),
    'pohChallenges' : IDL.Vec(IDL.Tuple(IDL.Text, PohChallenges)),
    'callbackIssuedByProvider' : IDL.Vec(
      IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Int)))
    ),
    'token2ProviderAndUserData' : IDL.Vec(
      IDL.Tuple(IDL.Text, PohProviderAndUserData)
    ),
    'pohChallengePackages' : IDL.Vec(IDL.Tuple(IDL.Text, PohChallengePackage)),
    'pohUserChallengeAttempts' : IDL.Vec(
      IDL.Tuple(
        IDL.Principal,
        IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(PohChallengesAttemptV1))),
      )
    ),
    'providerUserIdToModclubUserIdByProviderId' : IDL.Vec(
      IDL.Tuple(IDL.Principal, RelShared)
    ),
    'wordList' : IDL.Vec(IDL.Text),
  });
  const PohTaskDataWrapperPlus = IDL.Record({
    'reward' : IDL.Float64,
    'votes' : IDL.Nat,
    'createdAt' : IDL.Int,
    'reservation' : IDL.Opt(Reserved),
    'minStake' : IDL.Int,
    'updatedAt' : IDL.Int,
    'pohTaskData' : IDL.Vec(PohTaskData),
    'isReserved' : IDL.Bool,
    'packageId' : IDL.Text,
    'requiredVotes' : IDL.Int,
  });
  const PohError = IDL.Variant({
    'pohCallbackNotRegistered' : IDL.Null,
    'invalidPackageId' : IDL.Null,
    'pohNotConfiguredForProvider' : IDL.Null,
    'challengeNotPendingForSubmission' : IDL.Null,
    'invalidToken' : IDL.Null,
    'attemptToCreateMultipleWalletsWithSameIp' : IDL.Null,
    'attemptToAssociateMultipleModclubAccounts' : IDL.Principal,
  });
  const Result_10 = IDL.Variant({
    'ok' : PohTaskDataWrapperPlus,
    'err' : PohError,
  });
  const VotePlusUser = IDL.Record({
    'userVoteDecision' : Decision__1,
    'userUserName' : IDL.Text,
    'userModClubId' : IDL.Principal,
    'userEmailId' : IDL.Text,
    'userVoteCreatedAt' : IDL.Int,
  });
  const PohTaskDataAndVotesWrapperPlus = IDL.Record({
    'reward' : IDL.Float64,
    'createdAt' : IDL.Int,
    'minStake' : IDL.Int,
    'updatedAt' : IDL.Int,
    'pohTaskData' : IDL.Vec(PohTaskData),
    'packageId' : IDL.Text,
    'requiredVotes' : IDL.Int,
    'voteUserDetails' : IDL.Vec(VotePlusUser),
  });
  const Result_9 = IDL.Variant({
    'ok' : PohTaskDataAndVotesWrapperPlus,
    'err' : PohError,
  });
  const PohTaskPlus = IDL.Record({
    'status' : ContentStatus,
    'reward' : IDL.Float64,
    'title' : IDL.Opt(IDL.Text),
    'profileImageUrlSuffix' : IDL.Opt(IDL.Text),
    'voteCount' : IDL.Nat,
    'createdAt' : IDL.Int,
    'reservation' : IDL.Opt(Reserved),
    'minStake' : IDL.Int,
    'updatedAt' : IDL.Int,
    'hasVoted' : IDL.Opt(IDL.Bool),
    'isReserved' : IDL.Bool,
    'packageId' : IDL.Text,
    'requiredVotes' : IDL.Int,
  });
  const Rule = IDL.Record({ 'id' : RuleId, 'description' : IDL.Text });
  const ProviderPlus = IDL.Record({
    'id' : IDL.Principal,
    'subaccounts' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Nat8))),
    'contentCount' : IDL.Nat,
    'rewardsSpent' : IDL.Nat,
    'name' : IDL.Text,
    'createdAt' : Timestamp,
    'description' : IDL.Text,
    'updatedAt' : Timestamp,
    'settings' : ProviderSettings,
    'activeCount' : IDL.Nat,
    'image' : IDL.Opt(Image),
    'rules' : IDL.Vec(Rule),
  });
  const VotingStats = IDL.Record({
    'cid' : IDL.Text,
    'status' : ContentStatus,
    'approvedCount' : IDL.Nat,
    'sourceId' : IDL.Text,
    'violatedRules' : IDL.Vec(ViolatedRules),
    'rejectedCount' : IDL.Nat,
  });
  const ProviderContentResponse = IDL.Record({
    'content' : IDL.Vec(ContentPlus),
    'voting' : IDL.Vec(VotingStats),
  });
  const ProviderPendingSummaries = IDL.Record({
    'totalCost' : IDL.Nat,
    'totalPending' : IDL.Nat,
  });
  const Result_8 = IDL.Variant({
    'ok' : ProviderPendingSummaries,
    'err' : IDL.Text,
  });
  const ProviderSummaries = IDL.Record({
    'totalCost' : IDL.Nat,
    'totalApproved' : IDL.Nat,
    'totalRejected' : IDL.Nat,
  });
  const Result_7 = IDL.Variant({ 'ok' : ProviderSummaries, 'err' : IDL.Text });
  const Result_5 = IDL.Variant({ 'ok' : Reserved, 'err' : IDL.Text });
  const UserStat = IDL.Record({
    'totalVotes' : IDL.Nat,
    'totalUsers' : IDL.Nat,
  });
  const ModerationTasksFilter = IDL.Record({
    'categories' : IDL.Opt(IDL.Vec(CategoryId)),
    'providers' : IDL.Opt(IDL.Vec(IDL.Principal)),
  });
  const Secret = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const Event = IDL.Record({ 'topic' : IDL.Text, 'payload' : IDL.Principal });
  const ConsumerPayload = IDL.Variant({
    'secrets' : IDL.Vec(Secret),
    'events' : IDL.Vec(Event),
    'admins' : IDL.Vec(IDL.Principal),
  });
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const HttpRequest = IDL.Record({
    'url' : IDL.Text,
    'method' : IDL.Text,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
  });
  const Token = IDL.Record({});
  const StreamingCallbackHttpResponse = IDL.Record({
    'token' : Token,
    'body' : IDL.Vec(IDL.Nat8),
  });
  const StreamingStrategy = IDL.Variant({
    'Callback' : IDL.Record({
      'token' : Token,
      'callback' : IDL.Func([Token], [StreamingCallbackHttpResponse], []),
    }),
  });
  const HttpResponse = IDL.Record({
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
    'upgrade' : IDL.Opt(IDL.Bool),
    'streaming_strategy' : IDL.Opt(StreamingStrategy),
    'status_code' : IDL.Nat16,
  });
  const AirdropMetadataImportPayload = IDL.Record({
    'userPoints' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Int)),
  });
  const TxIndex = IDL.Nat;
  const Timestamp__1 = IDL.Nat64;
  const TransferError = IDL.Variant({
    'GenericError' : IDL.Record({
      'message' : IDL.Text,
      'error_code' : IDL.Nat,
    }),
    'TemporarilyUnavailable' : IDL.Null,
    'BadBurn' : IDL.Record({ 'min_burn_amount' : Tokens }),
    'Duplicate' : IDL.Record({ 'duplicate_of' : TxIndex }),
    'BadFee' : IDL.Record({ 'expected_fee' : Tokens }),
    'CreatedInFuture' : IDL.Record({ 'ledger_time' : Timestamp__1 }),
    'TooOld' : IDL.Null,
    'InsufficientFunds' : IDL.Record({ 'balance' : Tokens }),
  });
  const Result__1 = IDL.Variant({ 'Ok' : TxIndex, 'Err' : TransferError });
  const Result_3 = IDL.Variant({
    'ok' : IDL.Vec(PohChallengesAttemptV1),
    'err' : PohError,
  });
  const Result_4 = IDL.Variant({ 'ok' : IDL.Text, 'err' : IDL.Text });
  const PohChallengeSubmissionRequest = IDL.Record({
    'numOfChunks' : IDL.Nat,
    'mimeType' : IDL.Text,
    'offset' : IDL.Nat,
    'challengeId' : IDL.Text,
    'dataSize' : IDL.Nat,
    'challengeDataBlob' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const PohChallengeSubmissionStatus = IDL.Variant({
    'ok' : IDL.Null,
    'notPendingForSubmission' : IDL.Null,
    'alreadySubmitted' : IDL.Null,
    'alreadyApproved' : IDL.Null,
    'alreadyRejected' : IDL.Null,
    'submissionDataLimitExceeded' : IDL.Null,
    'inputDataMissing' : IDL.Null,
    'incorrectChallenge' : IDL.Null,
  });
  const PohChallengeSubmissionResponse = IDL.Record({
    'submissionStatus' : PohChallengeSubmissionStatus,
    'challengeId' : IDL.Text,
  });
  const SubscribeMessage = IDL.Record({
    'callback' : IDL.Func([ContentResult], [], ['oneway']),
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const CanisterHttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : CanisterHttpResponsePayload,
  });
  const ProviderMeta = IDL.Record({
    'name' : IDL.Text,
    'description' : IDL.Text,
  });
  const ProviderMetaResult = IDL.Variant({
    'ok' : ProviderMeta,
    'err' : ProviderError,
  });
  const Validate = IDL.Variant({ 'Ok' : IDL.Text, 'Err' : IDL.Text });
  const VerifyHumanityResponse = IDL.Record({
    'status' : PohVerificationStatus,
    'token' : IDL.Opt(IDL.Text),
    'rejectionReasons' : IDL.Vec(IDL.Text),
  });
  const PohRulesViolated = IDL.Record({
    'ruleId' : IDL.Text,
    'challengeId' : IDL.Text,
  });
  const Result_1 = IDL.Variant({ 'ok' : IDL.Bool, 'err' : POHVoteError });
  const Result = IDL.Variant({ 'ok' : TxIndex, 'err' : IDL.Text });
  const ModClub = IDL.Service({
    'AdminCheckPohVerificationResp' : IDL.Func(
        [IDL.Text, IDL.Principal],
        [PohVerificationResponsePlus],
        [],
      ),
    'addProviderAdmin' : IDL.Func(
        [IDL.Principal, IDL.Text, IDL.Opt(IDL.Principal)],
        [ProviderResult],
        [],
      ),
    'addRules' : IDL.Func(
        [IDL.Vec(IDL.Text), IDL.Opt(IDL.Principal)],
        [IDL.Vec(IDL.Text)],
        [],
      ),
    'addToAllowList' : IDL.Func([IDL.Principal], [], []),
    'addToApprovedUser' : IDL.Func([IDL.Principal], [], []),
    'adminInit' : IDL.Func([], [], []),
    'adminUpdateEmail' : IDL.Func(
        [IDL.Principal, IDL.Text],
        [ProfileStable],
        [],
      ),
    'airdropMigratedUser' : IDL.Func(
        [IDL.Principal, IDL.Opt(IDL.Nat)],
        [Result_2],
        [],
      ),
    'airdropMigratedUsers' : IDL.Func([], [Result_2], []),
    'appendMigrationAirdropItem' : IDL.Func([IDL.Principal], [Result_2], []),
    'backup' : IDL.Func([IDL.Text, IDL.Text], [IDL.Nat], []),
    'burn' : IDL.Func([IDL.Opt(Subaccount), IDL.Nat], [], []),
    'canClaimLockedReward' : IDL.Func([IDL.Opt(Tokens)], [Result_14], []),
    'canReserveContent' : IDL.Func([IDL.Text], [Result_2], []),
    'checkIfUserOptToReciveAlerts' : IDL.Func([], [IDL.Bool], ['query']),
    'claimLockedReward' : IDL.Func(
        [Tokens, IDL.Opt(IDL.Principal)],
        [Result_2],
        [],
      ),
    'claimStakedTokens' : IDL.Func([Tokens], [Result_6], []),
    'collectCanisterMetrics' : IDL.Func([], [], []),
    'configurePohForProvider' : IDL.Func(
        [IDL.Principal, IDL.Vec(IDL.Text), IDL.Nat, IDL.Bool],
        [],
        [],
      ),
    'createPohVoteReservation' : IDL.Func([IDL.Text], [Result_13], []),
    'deregisterProvider' : IDL.Func([], [IDL.Text], []),
    'editProviderAdmin' : IDL.Func(
        [IDL.Principal, IDL.Principal, IDL.Text],
        [ProviderResult],
        [],
      ),
    'generateSigningKey' : IDL.Func([], [], []),
    'getActivity' : IDL.Func([IDL.Bool], [IDL.Vec(Activity)], ['query']),
    'getAdminProviderIDs' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'getAirdropBalance' : IDL.Func([], [IDL.Nat], []),
    'getAllContent' : IDL.Func([ContentStatus], [IDL.Vec(ContentPlus)], []),
    'getAllDataCanisterIds' : IDL.Func(
        [],
        [IDL.Vec(IDL.Principal), IDL.Vec(IDL.Text)],
        [],
      ),
    'getAllPohTasksForAdminUsers' : IDL.Func(
        [ContentStatus, IDL.Nat, IDL.Nat, IDL.Vec(IDL.Text), IDL.Int, IDL.Int],
        [IDL.Vec(PohTaskPlusForAdmin)],
        ['query'],
      ),
    'getAllProfiles' : IDL.Func([], [IDL.Vec(ProfileStable)], ['query']),
    'getAllUsersWantToReceiveAlerts' : IDL.Func(
        [],
        [IDL.Vec(IDL.Text)],
        ['query'],
      ),
    'getBackupCanisterId' : IDL.Func([], [IDL.Principal], []),
    'getCanisterLog' : IDL.Func(
        [IDL.Opt(CanisterLogRequest)],
        [IDL.Opt(CanisterLogResponse)],
        ['query'],
      ),
    'getCanisterMetrics' : IDL.Func(
        [GetMetricsParameters],
        [IDL.Opt(CanisterMetrics)],
        ['query'],
      ),
    'getContent' : IDL.Func([IDL.Text], [IDL.Opt(ContentPlus)], ['query']),
    'getContentCategories' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [IDL.Vec(ContentCategory)],
        ['query'],
      ),
    'getContentProviders' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, ProviderStable))],
        ['query'],
      ),
    'getContentResult' : IDL.Func([IDL.Text], [ContentResult], ['query']),
    'getDeployer' : IDL.Func([], [IDL.Principal], ['query']),
    'getImportedUsersStats' : IDL.Func([], [Result_12], ['query']),
    'getImportedUsersStatsByLevel' : IDL.Func(
        [UserLevel],
        [IDL.Text],
        ['query'],
      ),
    'getMigrationAirdropWhitelist' : IDL.Func([], [Result_11], ['query']),
    'getModeratorEmailsForPOHAndSendEmail' : IDL.Func([IDL.Text], [], []),
    'getModeratorLeaderboard' : IDL.Func(
        [IDL.Nat, IDL.Nat],
        [IDL.Vec(ModeratorLeaderboard)],
        [],
      ),
    'getPohAttempts' : IDL.Func([], [PohStableState], []),
    'getPohTaskData' : IDL.Func([IDL.Text], [Result_10], ['query']),
    'getPohTaskDataForAdminUsers' : IDL.Func([IDL.Text], [Result_9], ['query']),
    'getPohTasks' : IDL.Func(
        [ContentStatus, IDL.Nat, IDL.Nat],
        [IDL.Vec(PohTaskPlus)],
        ['query'],
      ),
    'getProfile' : IDL.Func([], [ProfileStable], ['query']),
    'getProfileById' : IDL.Func([IDL.Principal], [ProfileStable], ['query']),
    'getProvider' : IDL.Func([IDL.Principal], [ProviderPlus], []),
    'getProviderAdmins' : IDL.Func(
        [IDL.Principal],
        [IDL.Vec(ProfileStable)],
        [],
      ),
    'getProviderContent' : IDL.Func(
        [IDL.Principal, ContentStatus, IDL.Nat, IDL.Nat],
        [ProviderContentResponse],
        ['query'],
      ),
    'getProviderPendingSummaries' : IDL.Func(
        [IDL.Principal],
        [Result_8],
        ['query'],
      ),
    'getProviderRules' : IDL.Func([], [IDL.Vec(Rule)], ['query']),
    'getProviderSa' : IDL.Func(
        [IDL.Text, IDL.Opt(IDL.Principal)],
        [IDL.Vec(IDL.Nat8)],
        [],
      ),
    'getProviderSummaries' : IDL.Func([IDL.Principal], [Result_7], ['query']),
    'getReservedByContentId' : IDL.Func([IDL.Text], [Result_5], []),
    'getRules' : IDL.Func([IDL.Principal], [IDL.Vec(Rule)], ['query']),
    'getStats' : IDL.Func([], [UserStat], ['query']),
    'getTaskStats' : IDL.Func(
        [IDL.Int],
        [IDL.Nat, IDL.Nat, IDL.Nat, IDL.Nat],
        [],
      ),
    'getTasks' : IDL.Func(
        [IDL.Nat, IDL.Nat, IDL.Bool, ModerationTasksFilter],
        [IDL.Vec(ContentPlus)],
        ['query'],
      ),
    'getVotePerformance' : IDL.Func([], [IDL.Float64], ['query']),
    'handleSubscription' : IDL.Func([ConsumerPayload], [], []),
    'http_request' : IDL.Func([HttpRequest], [HttpResponse], ['query']),
    'http_request_update' : IDL.Func([HttpRequest], [HttpResponse], []),
    'importAirdropMetadata' : IDL.Func(
        [AirdropMetadataImportPayload],
        [IDL.Record({ 'status' : IDL.Bool })],
        [],
      ),
    'isEnabledVCForUser' : IDL.Func([], [IDL.Bool], ['query']),
    'isProviderAdmin' : IDL.Func([], [IDL.Bool], ['query']),
    'isReservedPOHContent' : IDL.Func([IDL.Text], [IDL.Bool], ['query']),
    'issueJwt' : IDL.Func([], [IDL.Text], []),
    'pohCallbackForModclub' : IDL.Func(
        [PohVerificationResponsePlus],
        [],
        ['oneway'],
      ),
    'populateChallenges' : IDL.Func([], [], []),
    'providerSaBalance' : IDL.Func(
        [IDL.Text, IDL.Opt(IDL.Principal)],
        [Tokens],
        [],
      ),
    'registerModerator' : IDL.Func(
        [IDL.Text, IDL.Opt(IDL.Text)],
        [ProfileStable],
        [],
      ),
    'registerProvider' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Opt(Image)],
        [IDL.Text],
        [],
      ),
    'registerUserToReceiveAlerts' : IDL.Func(
        [IDL.Principal, IDL.Bool],
        [IDL.Bool],
        [],
      ),
    'releaseBufferedTokens' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result_6],
        [],
      ),
    'releaseTokens' : IDL.Func([Tokens], [Result__1], []),
    'releaseTokensFor' : IDL.Func([Tokens, IDL.Principal], [Result__1], []),
    'removeProviderAdmin' : IDL.Func(
        [IDL.Principal, IDL.Principal],
        [ProviderResult],
        [],
      ),
    'removeRules' : IDL.Func([IDL.Vec(RuleId), IDL.Opt(IDL.Principal)], [], []),
    'reserveContent' : IDL.Func([IDL.Text], [Result_5], []),
    'resetUserChallengeAttempt' : IDL.Func([IDL.Text], [Result_3], []),
    'restore' : IDL.Func([IDL.Text, IDL.Nat], [Result_4], []),
    'retiredDataCanisterIdForWriting' : IDL.Func([IDL.Text], [], ['oneway']),
    'retrieveChallengesForUser' : IDL.Func([IDL.Text], [Result_3], []),
    'sendVerificationEmail' : IDL.Func([IDL.Text], [IDL.Bool], []),
    'setLambdaToken' : IDL.Func([IDL.Text], [], []),
    'setMigrationAirdropWhitelist' : IDL.Func(
        [IDL.Vec(IDL.Principal)],
        [Result_2],
        [],
      ),
    'setModclubBuckets' : IDL.Func([], [], ['oneway']),
    'setRandomization' : IDL.Func([IDL.Bool], [], []),
    'showAdmins' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'shuffleContent' : IDL.Func([], [], []),
    'shufflePohContent' : IDL.Func([], [], []),
    'stakeTokens' : IDL.Func([IDL.Nat], [Result__1], []),
    'submitChallengeData' : IDL.Func(
        [PohChallengeSubmissionRequest],
        [PohChallengeSubmissionResponse],
        [],
      ),
    'submitHtmlContent' : IDL.Func(
        [
          IDL.Text,
          IDL.Text,
          IDL.Opt(IDL.Text),
          IDL.Opt(Level),
          IDL.Opt(IDL.Text),
        ],
        [IDL.Text],
        [],
      ),
    'submitImage' : IDL.Func(
        [
          IDL.Text,
          IDL.Vec(IDL.Nat8),
          IDL.Text,
          IDL.Opt(IDL.Text),
          IDL.Opt(Level),
          IDL.Opt(IDL.Text),
        ],
        [IDL.Text],
        [],
      ),
    'submitText' : IDL.Func(
        [
          IDL.Text,
          IDL.Text,
          IDL.Opt(IDL.Text),
          IDL.Opt(Level),
          IDL.Opt(IDL.Text),
        ],
        [IDL.Text],
        [],
      ),
    'subscribe' : IDL.Func([SubscribeMessage], [], []),
    'subscribeOnRsEvets' : IDL.Func([], [], []),
    'subscribePohCallback' : IDL.Func([SubscribePohMessage], [], []),
    'toJson' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'toggleAllowSubmission' : IDL.Func([IDL.Bool], [], []),
    'toggleVCForUser' : IDL.Func([IDL.Bool], [Result_2], []),
    'transform' : IDL.Func(
        [TransformArgs],
        [CanisterHttpResponsePayload],
        ['query'],
      ),
    'updateEmail' : IDL.Func([IDL.Text], [ProfileStable], []),
    'updateProvider' : IDL.Func(
        [IDL.Principal, ProviderMeta],
        [ProviderMetaResult],
        [],
      ),
    'updateProviderLogo' : IDL.Func(
        [IDL.Principal, IDL.Vec(IDL.Nat8), IDL.Text],
        [IDL.Text],
        [],
      ),
    'updateRules' : IDL.Func([IDL.Vec(Rule), IDL.Opt(IDL.Principal)], [], []),
    'validate' : IDL.Func([IDL.Reserved], [Validate], []),
    'verifyHumanity' : IDL.Func([IDL.Text], [PohVerificationResponsePlus], []),
    'verifyUserHumanityForModclub' : IDL.Func([], [VerifyHumanityResponse], []),
    'vote' : IDL.Func(
        [ContentId, Decision, IDL.Opt(IDL.Vec(RuleId))],
        [IDL.Text],
        [],
      ),
    'votePohContent' : IDL.Func(
        [IDL.Text, Decision, IDL.Vec(PohRulesViolated)],
        [Result_1],
        [],
      ),
    'whoami' : IDL.Func([], [IDL.Principal], []),
    'withdrawModeratorReward' : IDL.Func(
        [Tokens, IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
  });
  return ModClub;
};
export const init = ({ IDL }) => {
  const ENV = IDL.Record({
    'wallet_canister_id' : IDL.Principal,
    'vesting_canister_id' : IDL.Principal,
    'old_modclub_canister_id' : IDL.Principal,
    'modclub_canister_id' : IDL.Principal,
    'rs_canister_id' : IDL.Principal,
    'auth_canister_id' : IDL.Principal,
    'modclub_assets_canister_id' : IDL.Principal,
  });
  return [ENV];
};
